<template>
    <div style="padding-top: 8px !important">
        <WidgetsButton :type="'button'" :classes="'btn p-0 position-relative header-wishlist'" :id="'cart_sidebar_btn'"
            @click="cartStyle == 'cart_sidebar' && cartStore.toggleCartsDrawer()">
            <Icon name="ri-shopping-cart-line"></Icon>
            <span class="position-absolute top-0 start-100 translate-middle badge" v-if="cartStore.carts.length">
                {{ cartStore.carts.length }} <span class="visually-hidden">{{ 'Unread Messages' }}</span>
            </span>
        </WidgetsButton>

        <!-- Cart Drawer -->
        <div class="onhover-div" :class="{ 'fixed-cart': true, 'show': cartStore.cartToggle }">
            <div class="cart-title">
                <h4>{{ $t('Shopping Cart') }}</h4>
                <a href="javascript:void(0)" @click="cartStore.toggleCartsDrawer(false)">
                    <Icon name="ri-close-line"></Icon>
                </a>
            </div>

            <v-container>
                <v-expansion-panels v-model:model-value="cartStore.customersPanelsCarts">
                    <v-expansion-panel v-for="cart in cartStore.carts" :key="cart.customer_name" :value="cart.customer"
                        @group:selected="selectedCustomerCart($event.value, cart)">
                        <v-expansion-panel-title>
                            <div class="d-flex align-center">
                                <Icon name="ri-shopping-cart-line"></Icon>

                                <span class="mx-1"></span>
                                <span>{{ cart.customer_name }} <span class="quote2 pl-2">{{
                                    formatCurrency(cart.grand_total, cart.currency)
                                        }}</span> </span>
                                <span class="mx-2"></span>
                                <v-btn density="compact" size="30px" flat
                                    @click.native.stop="cartStore.removeCustomerCart(cart.customer)">
                                    <icon name="mynaui:trash"></icon>

                                </v-btn>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text disable-icon-rotate style="padding-right: 0px; padding-left: 0px;">
                            <ul class="cart-list">
                                <li class="product-box-contain" v-for="item in cart.items" :key="item.item_code">
                                    <a href="javascript:void(0)">
                                        <h5>{{ item.item_name }}</h5>
                                    </a>
                                    <div class="drop-cart">
                                        <a href="javascript:void(0)" class="drop-image">
                                            <img :src="item.image" class="img-fluid" :alt="item?.item_name">
                                        </a>
                                        <div class="drop-contain">

                                            <div style="display: flex; flex-direction: column; align-items: first baseline;">

                                                <!-- <div class="gram"> -->
                                                    <pre class="gram">{{ formatCurrency(item.price_list_rate,
                                                        cart.currency) }}</pre>
                                                <!-- </div> -->
    
                                                <!-- <div class="gram"> -->
                                                    <pre class="gram">{{ item.actual_qty - item.reserved_qty }} {{ item.stock_uom }}</pre>
                                                <!-- </div> -->
                                            </div>

                                            <div class="cart_qty qty-box product-qty">
                                                <div class="input-group h-100">

                                                    <WidgetsButton :classes="'btn qty-left-minus'"
                                                        :id="'add-to-cart' + item.item_code"
                                                        @onClick="cartStore.changeItemQty(cart, item, item.qty - 1)">
                                                        <Icon name="ri-subtract-line" v-if="item.qty > 1"></Icon>
                                                        <Icon name="ri-delete-bin-line" v-if="item.qty <= 1"
                                                            @click.prevent="cartStore.removeItemFromCart(cart, item)">
                                                        </Icon>
                                                    </WidgetsButton>
                                                    <input class="form-control input-number qty-input" type="number"
                                                        name="quantity" v-model="item.qty" readonly>
                                                    <WidgetsButton :classes="'btn qty-left-plus'"
                                                        :id="'qty-left-plus' + item.item_code"
                                                        @onClick="cartStore.changeItemQty(cart, item, item.qty + 1)">
                                                        <Icon name="ri-add-line"></Icon>
                                                    </WidgetsButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <br>


                            <div v-if="cart.items.length">
                                <div class="price-box">
                                    <h5>{{ $t('Grand Total') }}:</h5>
                                    <h4 class="fw-bold">{{ formatCurrency(cart.grand_total, cart.currency) }}
                                    </h4>
                                </div>
                                <div class="button-group">



                                    <v-btn @click="proceedOrder(cart.customer)" class="fw-bold"
                                        style="color:white; background-color: var(--theme-color);"
                                        :loading="!cart.validated" :disabled="!cart.validated" :ripple="false">{{
                                            $t("Proceed Order") }}</v-btn>
                                </div>
                            </div>

                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>

            <div class="empty-cart-box" v-if="!cartStore.carts.length">
                <i class="ri-shopping-cart-line"></i>
                <h5>{{ $t("Your cart is currently empty") }}</h5>
            </div>

        </div>
        <!-- <div class="bg-overlay" :class="{ 'show': cartStore.cartToggle && cartStore.cartToggle }"
            @click="cartStyle == 'cart_sidebar' && cartStore.toggleCartsDrawer(false)"></div>
        Free Shipping Celebration 
        <div class="confetti-wrapper" :class="{ 'show': confetti == 1 && cartStore.cartToggle }">
            <div v-for="item in confettiItems" :key="item">
                <div :class="`confetti-${item}`"></div>
            </div>
        </div>
        Variation Customize
        <WidgetsModalsCommon :showModal="variationModal" @close="variationProductModal(item)"
            :modalClass="'variation-modal'">
            <WidgetsModalsVariationModal :product="singleVeriationProduct" @close="variationProductModal(item)" />
        </WidgetsModalsCommon> -->
    </div>
</template>

<script setup lang="ts">
import type { Cart } from "~/lib/features/cart/data/models/cart_model";
import { useCartStore } from "~/lib/features/cart/store/cart_store"

let props = defineProps({
    style: { type: [String, Object], default: 'basic' }
})

function selectedCustomerCart(selected: boolean, cart: Cart) {
    if (selected) {
        cartStore.refreshCart(cart)
    }

}

// let { Settings, ThemeOptions } = storeToRefs(useDataStore())
const router = useRouter()
const cartStore = useCartStore()

let cartStyle = ref('cart_sidebar');
// let shippingFreeAmt = ref(0)
let CartTotal = ref(0);
// let shippingCal = ref(0);
// let confetti = ref(0);
// let confettiItems = Array.from({ length: 150 }, (_, index) => index);
// let singleVeriationProduct = ref({})
// let variationModal = ref(false)

function proceedOrder(customer: string) {
    cartStore.toggleCartsDrawer(false)
    router.push(`/cart/${customer}`)
}

// function variationProductModal(item) {
//     if (variationModal.value == false) {
//         variationModal.value = true
//         singleVeriationProduct.value = item
//     } else {
//         variationModal.value = false
//     }
// }

function cartToggle(value) {
    cartStore.cartToggle = value
}


watch(CartTotal, () => {
    // const setting = Settings.value;
    // shippingFreeAmt.value = setting?.values?.general?.min_order_free_shipping;
    // shippingCal.value = (CartTotal.value * 100) / shippingFreeAmt.value;

    // if (shippingCal.value > 100) {
    //     shippingCal.value = 100;
    //     if (confetti.value == 0) {
    //         confetti.value = 1;
    //         setTimeout(() => {
    //             confetti.value = 2;
    //         }, 4500);
    //     }
    // } else {
    //     confetti.value = 0;
    // }
});

onMounted(() => {
    // cartStore.invalidateInvoice()
    // setTimeout(()=>{
    //     cartStore.applyInvoiceOffersDiscounts()
    // }, 2000)
})

// onMounted((total) => {
// cartStyle.value = ThemeOptions.value.options?.general?.cart_style;
// const setting = Settings.value;
// shippingFreeAmt.value = setting?.values?.general?.min_order_free_shipping;

// });

</script>

<style lang="scss" scoped>
.main {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    background-color: #f6f6f6;
}

.one {
    width: 270px;
    background-color: #000;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pic1 {
    position: relative;
    right: 22px;
    top: 23px;
}

.pic2 {
    position: relative;
    left: 25px;
    top: 17px;
}

.pic3 {
    position: relative;
    right: 13px;
    bottom: 18px;
}

.pic4 {
    position: relative;
    left: 14px;
    bottom: 21px;
}

.project {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #fd4040;
}

.quote {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff;

}




.two {
    // width: 270px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.star {
    font-size: 20px !important;
    color: #b1b4b7;
}

.dot {
    font-size: 20px !important;
    color: #b1b4b7;
}

.round {
    border-radius: 50%;
    background-color: #eee;
    width: 33px;
    height: 33px;
    margin-bottom: -10px;
    align-items: center;
    justify-content: center;
}

.name {
    font-size: 22px;
    color: #464e56;
    font-weight: 600;
    text-align: left;
}

.quote2 {
    font-size: 12px;
    font-weight: 500;
    color: #868e94;
    text-align: left;
}

.img1 {
    position: relative;
    left: 20px;
    z-index: 28;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
}

.img2 {
    position: relative;
    left: 10px;
    z-index: 29;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
}

.img3 {
    z-index: 30;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
}

.task {
    color: #727475;
}

.date {
    color: #727475;
}

.imgfix {
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 2px;
}
</style>